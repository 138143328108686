import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from "../components/Homepage";

export default function Home() {
  return (
    <Layout>
      <section>
        <div>
          <Helmet>
            <title>Maker Recipe Sdn. Bhd.</title>
          </Helmet>
          <Homepage />
        </div>
      </section>
    </Layout>
    
  )
}
