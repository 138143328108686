import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import '../styles/homepage.css'
import { Container, Row, Col, Button } from 'reactstrap'

export default function Homepage() {
    return (
        <div>
            <div className="hometop">
                {/* first row of Homepage */}
                <Container>
                    <Row className="hometop-row1">
                        <Col md="6" lg="6" className='posterSection'>
                            <div className='posterContainer'>
                                <StaticImage 
                                    src="../images/homePageImages/SelamatHariRaya.png" 
                                    alt="Selamat Hari Raya"
                                    width={450}
                                    height={450} />
                            </div>
                            
                        </Col>
                        <Col md="6" lg="6">
                            <Row>
                                <Col md="12" className="logo-container">
                                <StaticImage 
                                    src="../images/makerLogo.png" 
                                    alt="Maker Recipe Logo"
                                    width={130}
                                    height={130} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="hometop-row1-title">
                                    <h2>Cap’O’ by Maker’s</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="hometop-row1-subtitle">
                                    <p>
                                        The Brand Cap’O’ has been around since the 1970s and 
                                        it is well known among the people of Northern Malaysia. 
                                        It’s rich aroma and slight spiciness compared to others 
                                        really gave the extra “oomph!” that we Malaysians love. 
                                        Although Maker’s took over the brand since 2007, the recipe 
                                        still remains true to the original and we have made it our 
                                        special duty to safeguard this recipe of Kedah Curry heritage which is Cap’O’!
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Second row of homepage */}
            <div className="homeSecondLayer">
                <Container>
                    <Row className="home-row2">
                        <Col md="6">
                            <Row>
                                <Col md="12" className="home-row2Title">
                                    <h2>About Maker’s</h2>
                                </Col>
                                <Col md="12" className="home-row2Subtitle">
                                    <p>
                                        Maker Recipe Sdn Bhd is a Bumiputera company established on 
                                        June 2007. Operates as a manufacturer processing Halal food 
                                        products own brand as well as OEM.
                                    </p>
                                </Col>
                                <Col md="12" className="home-row2Button">
                                    <Button color="secondary">
                                        <Link to="/about">Read More</Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6"> </Col>
                    </Row>
                </Container>
            </div>
            {/* Third row of homepage */}
            <div className="homeThirdLayer">
                <Container>
                    <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <h2>Top Products</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="latestProductContainer">
                            <div className="zoom-productContainer">
                                {/* <Link to="/"> */}
                                    <Row>
                                        <Col md="12">
                                            <StaticImage 
                                                src="../images/latestProduct/Ikan25gm.png" 
                                                alt="Latest Product 1"
                                                width={500}
                                                height={500} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="latestProductName">
                                            <p>Ikan 25 gram</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="12" className="latestProductDetails">
                                            <p>View Details..</p>
                                        </Col>
                                    </Row> */}
                                {/* </Link> */}
                            </div>
                            <div className="zoom-productContainer">
                                {/* <Link to="/"> */}
                                    <Row>
                                        <Col md="12">
                                            <StaticImage 
                                                src="../images/latestProduct/KariDaging25gm.png" 
                                                alt="Latest Product 2"
                                                width={500}
                                                height={500} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="latestProductName">
                                            <p>Kari Daging 25 gram</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="12" className="latestProductDetails">
                                            <p>View Details..</p>
                                        </Col>
                                    </Row> */}
                                {/* </Link> */}
                            </div>
                            <div className="zoom-productContainer">
                                {/* <Link to="/"> */}
                                    <Row>
                                        <Col md="12">
                                            <StaticImage 
                                                src="../images/latestProduct/KariKorma25gm.png" 
                                                alt="Latest Product 3"
                                                width={500}
                                                height={500} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="latestProductName">
                                            <p>Kari Korma 25 gram</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="12" className="latestProductDetails">
                                            <p>View Details..</p>
                                        </Col>
                                    </Row> */}
                                {/* </Link> */}
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md="12" className="homeThirdLayer-button">
                            <Button>
                                <Link to="/">More Products</Link>
                            </Button>
                        </Col>
                    </Row> */}
                </Container>
            </div>
            {/* Homepage fourth row -  Quality & Reliability */}
            <div className="homeFourthLayer">
                <Container className="homeFourthLayerContainer">
                    <Row>
                        {/* <Col md="4"></Col> */}
                        <Col md="12" className="QualityReliabilityContainer">
                            <Row>
                                <Col md="12" className="QualityReliability-title">
                                    <h2>Quality & Reliablity</h2>
                                </Col>
                                <Col md="12" className="QualityReliability-subtitle">
                                    <p>
                                        As a 100% Muslim Bumiputera company, it is our Jihad
                                        to make sure that only healthy, clean and quality foods 
                                        are served to our consumers as required to be Halal and Toyyiban.
                                    </p>
                                </Col>
                                <Col md="12">
                                    <StaticImage 
                                        src="../images/SirimLogo.png" 
                                        alt="Sirim and Standard Logo"
                                        width={200}
                                        height={100} />
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col md="6">
                            <Row>
                                <Col md="12">
                                    <h2>Total Halal Commitment</h2>
                                </Col>
                                <Col md="12">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis 
                                        aute irure dolor in reprehenderit in voluptate velit esse cillum 
                                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
                                        non proident, sunt in culpa qui officia deserunt mollit anim id 
                                        est laborum.
                                    </p>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>
                </Container>
            </div>
            {/* Homepage last row - Contact Us */}
            <div className="homepage-contactUsRow">
                <div>
                    <Container className="contactUsRowContainer">
                        <Row>
                            <Col md="4" className="ContactUsRow-Title">
                                <h2>Help Us To Serve You Better</h2>
                            </Col>
                            <Col md="4" className="ContactUsRow-Button">
                                <Button>
                                    <Link to="/contact">Contact Us</Link>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}
